import { Injectable } from '@angular/core';
import { BaseWebApiService } from './_base-web-api.service';
import { UrlNames } from './urlProfiler';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, tap, mergeMap, of, BehaviorSubject, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class VimeoService {
	private _accessToken: string = undefined;

	private currentUpload: BehaviorSubject<CurrentVimeoUploadProps> =
		new BehaviorSubject<CurrentVimeoUploadProps>(null);
	constructor(private apiService: BaseWebApiService) {}

	public authenticate(): Observable<any> {
		const header = new HttpHeaders({
			Authorization: `basic ${window.btoa(
				`${environment.vimeo.clinetId}:${environment.vimeo.clientSecret}`
			)}`,
			'Content-Type': 'application/json',
			Accept: 'application/vnd.vimeo.*+json;version=3.4',
		});
		const body = {
			grant_type: 'client_credentials',
			scope: 'public',
		};
		return this.apiService
			.post(
				`${UrlNames.vimeoAuth}`,
				body,
				undefined,
				'vimeo_api',
				undefined,
				undefined,
				undefined,
				header
			)
			.pipe(
				tap((res) => {
					console.log(res);
					this._accessToken = res;
				})
			);
	}

	public get currentUploadProps(): CurrentVimeoUploadProps {
		return this.currentUpload.value;
	}

	private set currentUploadProps(value: CurrentVimeoUploadProps) {
		this.currentUpload.next(value);
	}
	private set updateUploadOffset(value: number) {
		const currentValue = this.currentUpload.value;

		this.currentUpload.next({
			...currentValue,
			uploadOffset: value,
		});
	}

	public resetCurentVimeoUploadProps(): void {
		this.currentUploadProps = null;
	}

	public prepareTusUploadForVimeo(
		file: File,
		videoName: string = file.name,
		embedPrivacy: 'private' | 'public' | 'whitelist' = 'public',
		viewPrivacy:
			| 'anybody'
			| 'contacts'
			| 'disable'
			| 'nobody'
			| 'password'
			| 'unlisted'
			| 'users' = 'anybody',
		downloadPrivacy: boolean = false
	): Observable<any> {
		const headers = {
			Authorization: `bearer ${environment.vimeo.personalAccessToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/vnd.vimeo.*+json;version=3.4',
		};
		const body = {
			upload: {
				approach: 'tus',
				size: file.size,
			},
			name: videoName,
			description: '',
			privacy: {
				view: viewPrivacy,
				embed: embedPrivacy,
			},
		};
		return this.apiService
			.post(
				`${UrlNames.tusUpload}`,
				body,
				undefined,
				'vimeo_api',
				undefined,
				undefined,
				undefined,
				headers
			)
			.pipe(
				tap((tusResponse: any) => {
					console.log(
						'got upload link',
						tusResponse.upload.upload_link
					);
					this.currentUploadProps = {
						uploadLink: tusResponse.upload.upload_link,
						file: file,
						uploadOffset: 0,
						embedLink: tusResponse.player_embed_url,
					};
				})
			);
	}

	public uploadVideo(): Observable<any> {
		if (!this.currentUploadProps) {
			return of(null);
		}
		const headers = {
			'Tus-Resumable': '1.0.0',
			'Upload-Offset': `${this.currentUploadProps.uploadOffset}`,
			'Content-Type': 'application/offset+octet-stream',
		};
		return this.apiService.patch(
			this.currentUploadProps.uploadLink,
			this.currentUploadProps.file,
			undefined,
			undefined,
			undefined,
			headers,
			true
		);
	}

	public verifyTusUpload(): Observable<any> {
		const header = {
			'Tus-Resumable': '1.0.0',
			Accept: 'application/vnd.vimeo.*+json;version=3.4',
		};
		return this.apiService
			.head(
				this.currentUploadProps.uploadLink,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				header,
				true
			)
			.pipe(
				mergeMap((verifyResponse: any) => {
					const totalSize =
						verifyResponse.headers.get('Upload-Length');
					const uploadedSize =
						verifyResponse.headers.get('Upload-Offset');
					if (totalSize == uploadedSize) return of(true);
					this.updateUploadOffset = uploadedSize;
					return of(false);
				})
			);
	}
	public getVideoTranscodeStatus(vid: string): Observable<any> {
		const header = {
			Authorization: `bearer ${environment.vimeo.personalAccessToken}`,
			Accept: 'application/vnd.vimeo.*+json;version=3.4',
		};
		return this.apiService
			.get(
				`/videos/${vid}`,
				null,
				'vimeo_api',
				undefined,
				undefined,
				undefined,
				header
			)
			.pipe(
				map((verifyResponse: any) => {
					return verifyResponse;
				})
			);
	}
}

export interface CurrentVimeoUploadProps {
	uploadLink: string;
	file: File;
	uploadOffset: number;
	embedLink: string;
}
