import {
	AfterViewInit,
	Directive,
	ElementRef,
	Host,
	Input,
} from '@angular/core';

@Directive({
	selector: '[table-action-button]',
})
export class TableActionButtonDirective implements AfterViewInit {
	@Input() data: any;
	@Input() condition: { lhs: any; op: any; rhs: any };
	@Input() conditions: { lhs: any; op: any; rhs: any }[];
	constructor(@Host() private el: ElementRef) {}
	ngAfterViewInit(): void {
		if (!this.data) return;
		if (this.condition) {
			if (
				!this.getConditionResult(
					this.condition.lhs,
					this.condition.op,
					this.condition.rhs
				)
			) {
				this.el.nativeElement.remove();
			}
		}

		if (this.conditions && this.conditions.length) {
			const conditionsMet = this.conditions.every((condition) => {
				const lhs = this.data[condition.lhs];
				const rhs = condition.rhs;
				const op = condition.op;
				return this.getConditionResult(condition.lhs,op, rhs);
			});

			if (!conditionsMet) {
				this.el.nativeElement.remove();
			}
		}
	}

	private getConditionResult(lhs, op, rhs): boolean {
		switch (op) {
			case '!==':
			case '!=':
				return this.data[lhs] != rhs;
			case '>':
				return this.data[lhs] > rhs;
			case '<':
				return this.data[lhs] < rhs;
			case '==':
				return this.data[lhs] == rhs;
			case '===':
				return this.data[lhs] === rhs;
			default:
				return false;
		}
	}
}
