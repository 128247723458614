import { Environment } from './model/environment.interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// Only for triggering staging build. Can be removed anytime.

export const environment: Environment = {
	production: false,
	test: false,
	cloudApiUrl: 'https://regenisisstaging-api.surgelearning.ca',
	helpSystemApiUrl: 'https://surgelearning2.zendesk.com/api/v2/help_center',
	// End point needs to be updated once instance is finalized for Regenisis Surveys
	// regenisisSurvey: 'https://d3otnmx1nz21dr.cloudfront.net/surveys',
	regenisisSurvey: 'https://survey-staging.surgelearning.ca/surveys',
	scorm: {
		apiUrl: 'https://surgelearning.engine.scorm.com',
		uname: 'RusticiEngine',
		key: 'dEXpwDXmQvBvBYd94Z3HSiebS0DbdNl',
		engineTenantName: 'surge-beta',
		redirectUrl: 'https://regenisisstaging.surgelearning.ca/scormprogress',
		registrationPrefix: 'rstg',
	},
	ms: {
		// clientId: '0a675555-39aa-438d-aed5-24dbdf7bbd42', --> old
		clientId: 'c77ad998-6f9a-4d92-bc76-6960d440ece0', // --> Regenisis
		// clientId: '2c6b8a9c-426d-4afa-aec6-111576b1b032', --> RegenisisDev (one with SAML and cognito)
		redirectUri:
			// 'https://surgedev.auth.ca-central-1.amazoncognito.com/saml2/idpresponse',
			'https://regenisisstaging.surgelearning.ca',
		// 'http://localhost:4200',
	},
	cognito: {
		apiUrl: 'https://surgedev.auth.ca-central-1.amazoncognito.com/',
		idp: 'AADRegenisisDev',
		redirectUri: 'https://regenisisstaging.surgelearning.ca/auth/fetch',
		resType: 'TOKEN',
		clientId: 'p19udgbb4j0nm0mp6f33fqdud',
		scope: 'email%20openid',
	},
	cognitoAuthUrl:
		'https://regenisisstagingaliaspooldomain.auth.ca-central-1.amazoncognito.com',
	cognitoAuthClientId: '7t1b005pl4egjpqvf5up5udkch',
	// vimeo: {
	// 	clientSecret:
	// 		'Fs6SshZ0lsSiGbhykQJwqm3v07DYYFn/sPi9wcb+QuX5kqI8UxMU7CT4C2KkGsnHr6tTEhn64UeuEZ6XViicZqG2R8alTL2N2dJZOlmshvKwfRGbkxHsRWKQLo9stncQ',
	// 	clinetId: '17c5b6617eaea454505be770863f05f36b0621f4',
	// 	apiUrl: 'https://api.vimeo.com',
	// 	personalAccessToken: '4b796c80558d114a1a29373a62e0f22e',
	// },
	vimeo: {
		clientSecret:
			'RALJXIjS6I7tXH1/woss1dGI5rgD4oOz2Sa1MTen03G33W3G1nJJsvBB05PBcJ6phRwrXs5MJ/gLa0/RoMkR33DHoI1DENKcNAnKH+irBT4iIYGN8DHFjS567TCwX6Ef',
		clinetId: 'd2e45bbefbb6eb1cb2556262776c6ddeb521595c',
		apiUrl: 'https://api.vimeo.com',
		personalAccessToken: '0c65940b4ac6b9b2b938883112b8db0c',
	},
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
