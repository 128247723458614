<ng-select
	[id]="id"
	[placeholder]="placeholder | translate"
	[dropdownPosition]="dropdownPosition"
	[items]="options"
	[ngClass]="classes"
	[clearable]="false"
	[searchable]="false"
	[hidden]="hidden"
	[bindLabel]="'name'"
	[bindValue]="bindValue"
	(change)="ngSelectChange($event)"
	[(ngModel)]="value"
>
	<ng-template ng-option-tmp let-item="item">
		<div title="{{ item.name }}" class="ellipsis-text">{{ item.name }}</div>
	</ng-template>
</ng-select>
