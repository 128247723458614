import {
	Component,
	OnInit,
	Optional,
	Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-popup',
	templateUrl: './confirmation-popup.component.html',
	styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {

	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data,
		private dialogRef: MatDialogRef<any>
	) {}

	ngOnInit(): void {
	}
	close(flag: boolean = false) {
		this.dialogRef.close(flag);
	}
}
