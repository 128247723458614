import { createAction, props } from '@ngrx/store';
import {
	LoginToekInterface,
	UserDetailsInterface,
	UserLoginInterface,
} from 'src/app/core/models/user-details.interface';
import { UserPrivilageInterface } from 'src/app/core/models/user-privilege.interface';

export enum AuthenticationActionType {
	LOGIN = '[AUTH] Login',
	LOGIN_SUCCESS = '[AUTH] Login Success',
	LOGIN_FAILURE = '[AUTH] Login Failure',
	LOGOUT = '[AUTH] Logout',
	LOGOUT_SUCCESS = '[AUTH] Logout Success',
	LOGIN_SSO = '[AUTH] LOGIN SSO',
	LOGIN_SSO_V2 = '[AUTH] LOGIN SSO V2',
	USER_PRIVILEGES = '[Auth] Initialise User Privileges',
	SET_NEW_PASSWORD = '[Auth] Set new password',
	SAVE_REDIRECT = '[Auth] Save Redirect',
	SAVE_TEMP_USERNAME = '[Auth] Save temporary username',
	TRACK_USER_SESSION = '[Auth] Track User Session',
	HANDLE_LINKED_USER_CHANGE = '[AUTH] Lilnked user change',
	SAVE_TOKEN = '[AUTH] save token',
	SAVE_USER_SESSION_ID = '[AUTH] save user session id',
	SAVE_SESSION_EXPIRY_TIME = '[Auth] save session expiry time',

	CLOSE_DIALOG = '[AUTH] close site dialog',
	OPEN_DIALOG = '[AUTH] open site dialog',
	OPEN_SESSION_EXPIRE_POPUP = '[AUTH]open session expire popup',

	LISTEN_STORAGE_CHANGE = '[AUTH] listen to local storage change',
	OPEN_LOGGED_OUT_DIALOG = '[AUTH] open logged out message dialog',
	CLOSE_LOGGED_OUT_DIALOG = '[AUTH] closed logged out dialog',

	//Inactivity Tracker
	START_TIMER = '[AUTH] start timer',
	STOP_TIMER = '[AUTH] stop timer',
	RESET_TIMER = '[AUTH] reset timer',
	REFRESH_SESSION = '[Auth] refresh session',
	UPDATE_LAST_ACTIVITY_TIME = '[Auth] update last activity time',

	// Security Question Setup Flow
	SETUP_SECURITY_QUESTIONS = '[Auth] setup security question',
	SAVE_SECURITY_QUESTIONS = '[Auth] save security questions',
}

export const login = createAction(
	AuthenticationActionType.LOGIN,
	props<{ loginPayload: UserLoginInterface }>()
);
export const loginSSOV2 = createAction(
	AuthenticationActionType.LOGIN_SSO_V2,
	props<{ code: string; state: string }>()
);
export const saveToken = createAction(
	AuthenticationActionType.SAVE_TOKEN,
	props<{ token: LoginToekInterface }>()
);
export const loginSSO = createAction(
	AuthenticationActionType.LOGIN_SSO,
	props<{ username: string; token: string }>()
);
export const openSiteDialog = createAction(
	AuthenticationActionType.OPEN_DIALOG,
	props<{ data: string[]; email: string; token: string }>()
);
export const closeDialog = createAction(
	AuthenticationActionType.CLOSE_DIALOG,
	props<{ site: string | undefined; email: string; token: string }>()
);
export const setNewPassword = createAction(
	AuthenticationActionType.SET_NEW_PASSWORD,
	props<{ username: string }>()
);
export const saveTempUsername = createAction(
	AuthenticationActionType.SAVE_TEMP_USERNAME,
	props<{ username: string; password: string }>()
);
export const logout = createAction(
	AuthenticationActionType.LOGOUT,
	props<{ redirectUri?: string; redirect?: boolean }>()
);
export const logoutSuccess = createAction(
	AuthenticationActionType.LOGOUT_SUCCESS,
	props<{ redirectUri?: string; redirect?: boolean }>()
);
export const loginSuccess = createAction(
	AuthenticationActionType.LOGIN_SUCCESS,
	props<{
		user: UserDetailsInterface;
		privilege: UserPrivilageInterface;
		addNewUserParam?: boolean;
	}>()
);
export const loginFailure = createAction(
	AuthenticationActionType.LOGIN_FAILURE,
	props<{ error: string }>()
);

export const initUserPrivileges = createAction(
	AuthenticationActionType.USER_PRIVILEGES,
	props<{ username: string; site: string; sso: boolean }>()
);
export const saveRedirect = createAction(
	AuthenticationActionType.SAVE_REDIRECT,
	props<{ redirectUrl: string }>()
);

export const trackUserSession = createAction(
	AuthenticationActionType.TRACK_USER_SESSION,
	props<{
		payload: {
			ipAddress: string;
			locationId: number;
			userId: number;
		};
	}>()
);

export const saveUserSessionId = createAction(
	AuthenticationActionType.SAVE_USER_SESSION_ID,
	props<{ sessionId: number }>()
);
export const handleLinkedUserChange = createAction(
	AuthenticationActionType.HANDLE_LINKED_USER_CHANGE,
	props<{ payload: { code: string; username: string } }>()
);

export const listenStorageChange = createAction(
	AuthenticationActionType.LISTEN_STORAGE_CHANGE
);
export const openLoggedOutModal = createAction(
	AuthenticationActionType.OPEN_LOGGED_OUT_DIALOG
);
export const closeLoggedOutModal = createAction(
	AuthenticationActionType.CLOSE_LOGGED_OUT_DIALOG
);
export const startTimer = createAction(AuthenticationActionType.START_TIMER);
export const stopTimer = createAction(AuthenticationActionType.STOP_TIMER);
export const resetTimer = createAction(AuthenticationActionType.RESET_TIMER);
export const refreshUserSession = createAction(
	AuthenticationActionType.REFRESH_SESSION
);
export const updateLastActivityTime = createAction(
	AuthenticationActionType.UPDATE_LAST_ACTIVITY_TIME,
	props<{ time: number }>()
);
export const saveSessionExpiryTime = createAction(
	AuthenticationActionType.SAVE_SESSION_EXPIRY_TIME,
	props<{ time: number }>()
);
export const openSessionExpirePopup = createAction(
	AuthenticationActionType.OPEN_SESSION_EXPIRE_POPUP
);

export const setupSecurityQuestion = createAction(
	AuthenticationActionType.SETUP_SECURITY_QUESTIONS
);
export const saveSecurityQuestions = createAction(
	AuthenticationActionType.SAVE_SECURITY_QUESTIONS,
	props<{ payload?: any }>()
);
