import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, forkJoin, map, Observable } from 'rxjs';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getSelectedLocation,
	getSettingValueById,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import {
	CourseDeatilsInterface,
	LPCourseInterface,
	MyCourseListInterface,
	MyCourseListResponseWithTotalTime,
} from '../_models/course-details.interface';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { environment } from 'src/environments/environment';
import { TestCaptureInterface } from 'src/app/core/models/take-test.interface';
import {
	PESurveyInterface,
	PESurveySubmitINterface,
} from 'src/app/shared/models/program-evaluation-survey.model';
import { DurationFormatPipe } from 'src/app/shared/directives/duration-format.pipe';
import { SettingsMap } from '../../user-settings/_models/settins-map.enum';
import moment from 'moment';
import { LocalStorageKey } from 'src/app/core/models/local-storage-key.enum';
import { SendFeedBackInterface } from '../_models/send-feedback.interface';
import {
	SaveCourseCompletionStatusInterface,
	SaveCourseProgressInterface,
} from '../_models/save-course-completion-status.interface';
import { CourseQuizSummaryInterface } from '../_models/course-quiz.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { SaveSCORMCourseTestDataInterface } from '../_models/scorm-course-test.model';
import { CobasTestDetailsInterface } from '../_models/cobas.interface';

@Injectable({
	providedIn: 'root',
})
export class MyCourseService {
	private _locationId: number;
	private _userId: number;
	private _displayName: string;
	private _firstName: string;
	private _lastName: string;
	private supervisor1: number;
	private supervisor2: number;
	public attachment: any;
	public coursetitle: any;
	public courseid: any;
	privileges: any;
	username: any;

	public extraCourseListParams = {
		educationYear: null,
		hideCoursePastDue: null,
	};
	constructor(
		private sharedStore: Store<SharedState>,
		private apiService: BaseWebApiService,
		private durationPipe: DurationFormatPipe,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._userId = res.userId;
					this.username = res.emailId ?? res.userName;
					this._displayName = res.displayName;
					this.supervisor1 = res.primaryManagerId;
					this.supervisor2 = res.secondaryManagerId;
					this._firstName = res.firstName;
					this._lastName = res.lastName;
				},
			});
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value.location)
			)
			.subscribe({
				next: (res) => {
					this._locationId = res.location?.id;
				},
			});

		this.sharedStore
			.pipe(
				select(
					getSettingValueById(
						SettingsMap.my_courses_view_next_year,
						SettingsMap.my_courses_hide_future_override,
						SettingsMap.my_courses_hide_future
					)
				),
				filter((value) => !!value)
			)
			.subscribe({
				next: ([nextYear, hideFuture, hideFuture_2]) => {
					if (nextYear) {
						this.extraCourseListParams = {
							...this.extraCourseListParams,
							educationYear:
								this.mcExtraParamsFromLS.educationYear ??
								moment().year(),
						};
						if (!this.mcExtraParamsFromLS.educationYear)
							this.updateExtraParamInLocalStorage(
								moment().year()
							);
					}
					if (hideFuture && hideFuture_2) {
						this.extraCourseListParams = {
							...this.extraCourseListParams,
							hideCoursePastDue:
								this.mcExtraParamsFromLS.hideCoursePastDue ?? 1,
						};

						if (
							this.mcExtraParamsFromLS.hideCoursePastDue ===
								undefined ||
							this.mcExtraParamsFromLS.hideCoursePastDue === null
						)
							this.updateExtraParamInLocalStorage(undefined, 1);
					}
				},
			});
	}

	public getAllCourses(status: number = 1): Observable<
		[
			MyCourseListResponseWithTotalTime,
			// Array<MyCourseListInterface>,
			Array<MyCourseListInterface>
		]
	> {
		return forkJoin([
			this.getMandatoryCourses(status),
			// this.getHistoryCourses(),
			this.getRecommendedCourses(),
		]);
	}

	public getMandatoryCourses(
		courseStatus: number = 1
	): Observable<MyCourseListResponseWithTotalTime> {
		return this.apiService
			.get<MyCourseListResponseWithTotalTime>(
				`${UrlNames.myCourses}${UrlNames.myCoursesMandatory}/${this._userId}`,
				{
					status: courseStatus,
					...this.extraCourseListParams,
				}
			)
			.pipe(
				map(({ totalCoursesLength, data }) => {
					return {
						totalCoursesLength,
						data: data.map((y) => {
							return {
								...y,
								educationGroupName:
									!y.groups || !y.groups.length
										? 'ASSIGNED_TO_USER'
										: y.groups
												.map((x) => x.groupName)
												.join(', '),
								duration: this.durationPipe.transform(
									y.duration
								),
								_due: this.getDueDateType(
									y.isCompleted,
									y.dueDate
								),
								_postTestStatus: this.getPostTestStatus(
									y.hasPostTest,
									y.passedPosttest,
									y.isCompleted
								),
							};
						}),
					};
				})
			);
	}

	private getPostTestStatus(
		hasPostTest: boolean,
		passedPostTest: boolean,
		isCompleted: boolean
	): 'NA' | 'COMPLETED' | 'NOT_TAKEN' | 'NO_TEST' {
		if (!hasPostTest) return 'NO_TEST';

		if (!isCompleted) return 'NOT_TAKEN';

		if (!passedPostTest) return 'NA';

		return 'COMPLETED';
	}

	private getDueDateType(isCompleted: boolean, courseDueDate: string) {
		if (isCompleted) return null;
		const dueDate = moment(courseDueDate).endOf('day');
		const currentTime = moment();
		const futuretimestamp = moment().add(1, 'months').endOf('day');
		if (dueDate.isAfter(currentTime)) {
			if (dueDate.isAfter(futuretimestamp)) return null;
			return 2;
		}
		return 1;
	}

	public getLiveEvents(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.myLiveEvents}`,
			{
				userId: this._userId,
			},
			undefined,
			undefined,
			undefined,
			0
		);
	}

	public getHistoryCourses(): Observable<Array<MyCourseListInterface>> {
		return this.apiService.get(
			`${UrlNames.myCourses}${UrlNames.myCoursesHistory}/${this._userId}`,
			null
		);
	}

	public getRecommendedCourses(): Observable<Array<MyCourseListInterface>> {
		return this.apiService
			.get<Array<MyCourseListInterface>>(
				`${UrlNames.myCourses}${UrlNames.myCoursesRecommend}/${this._userId}`,
				null
			)
			.pipe(
				map((x) =>
					x.map((y) => {
						return {
							...y,
							duration: this.durationPipe.transform(y.duration),
							educationGroupName:
								!y.groups || !y.groups.length
									? 'RECOMMENDED_TO_USER'
									: y.groups
											.map((x) => x.groupName)
											.join(', '),
							_postTestStatus: this.getPostTestStatus(
								y.hasPostTest,
								y.passedPosttest,
								y.isCompleted
							),
						};
					})
				)
			);
	}

	/**
	 *
	 * @param cid - course Id
	 * @param selfAssigned - whether the user is opening the course from My Course Module or Resource Center
	 * @param userAssignmentId - userassignemntid of the course and 0 if LP or openeing from RC
	 * @param assignmentId - Assignment id of the course and 0 if opened from RC
	 * @returns
	 */

	public getCourseDetails(
		cid: number,
		selfAssigned: boolean = false,
		userAssignmentId?: number,
		assignmentId?: number
	): Observable<CourseDeatilsInterface> {
		return this.apiService
			.get(`${UrlNames.myCourses}${UrlNames.courseDetails}`, {
				locationId: this._locationId,
				courseId: cid,
				userId: this._userId,
				isLearningPathWay: false,
				selfAssignedCourse: selfAssigned,
				userAssignmentId: userAssignmentId ?? 0,
				assignmentId: assignmentId ?? 0,
				...this.extraCourseListParams,
			});
	}

	public getLPDetails(
		cid: number,
		selfAssigned: boolean = false,
		assignmentId: number
	): Observable<LPCourseInterface> {
		return this.apiService.get(
			`${UrlNames.myCourses}${UrlNames.courseDetails}`,
			{
				locationId: this._locationId,
				courseId: cid,
				userId: this._userId,
				isLearningPathWay: true,
				selfAssignedCourse: selfAssigned,
				userAssignmentId: 0,
				assignmentId: assignmentId ?? 0,
				educationYear: selfAssigned
					? null
					: this.mcExtraParamsFromLS.educationYear,
				hideCoursePastDue: selfAssigned
					? null
					: this.mcExtraParamsFromLS.hideCoursePastDue,
			}
		);
	}
	// public getResourceLPDetails(cid: number): Observable<LPCourseInterface> {
	// 	return this.apiService.get(`${UrlNames.courseDetails}`, {
	// 		courseId: cid,
	// 		userId: this._userId,
	// 		isLearningPathWay: true,
	// 	});
	// }
	// public getResourceCourseDetails(
	// 	cid: number
	// ): Observable<CourseDeatilsInterface> {
	// 	return this.apiService.get(`${UrlNames.courseDetails}`, {
	// 		courseId: cid,
	// 		userId: this._userId,
	// 		isLearningPathWay: false,
	// 	});
	// }

	public saveCourseProgress(
		payload: SaveCourseProgressInterface
	): Observable<Object> {
		// let body;
		// if (!lp) {
		// 	body = {
		// 		contentIds: contentId,
		// 		courseId: courseId,
		// 		learningPathWay: lp,
		// 		progressPercentage: progressPercentage,
		// 		actualTimeTaken: actualTimeTaken,
		// 		userId: this._userId,
		// 		userAssignmentId: userAssignmentId,
		// 		selfA,
		// 	};
		// } else {
		// 	body = {
		// 		courseId: courseId,
		// 		learningPathWay: lp,
		// 		progressPercentage: progressPercentage,
		// 		actualTimeTaken: actualTimeTaken,
		// 		userId: this._userId,
		// 		userAssignmentId: userAssignmentId,
		// 		selfA,
		// 	};
		// }

		payload = {
			...payload,
			userId: this._userId,
			locationId: this._locationId,
		};
		return this.apiService.post(
			`${UrlNames.myCourses}${UrlNames.courseProgress}`,
			payload
		);
	}

	public completeContent(
		payload: SaveCourseCompletionStatusInterface
	): Observable<Object> {
		payload = {
			...payload,
			supervisorId: this.supervisor1,
			supervisor2: this.supervisor2,
			userId: this._userId,
			email: this.username,
			locationId: this._locationId,
		};
		return this.apiService.post(
			`${UrlNames.myCourses}${UrlNames.contentComplete} `,
			payload
		);
	}

	/**
	 *
	 * @param assignId - the assignment id also known as the registrationId in rustici api
	 * @returns - launch link for scorm course.
	 */
	public getScormCourseLink(
		assignId: string | number
	): Observable<{ launchLink: string }> {
		return this.apiService
			.post<{ launchLink: string }>(
				`${UrlNames.scormRegistrations}/${assignId}/${UrlNames.launchLink}`,
				{
					expiry: 0,
					tracking: true,
					redirectOnExitUrl: environment.scorm.redirectUrl,
				},
				null,
				'scorm'
			)
			.pipe(
				map((res) => {
					return {
						launchLink: `${environment.scorm.apiUrl}${res.launchLink}`,
					};
				})
			);
	}

	/**
	 *
	 * @param rid - registrationId aka assignment id
	 * @returns - course progress object
	 */
	public checkScormCourseProgress(rid: number): Observable<any> {
		return this.apiService.get(
			`${UrlNames.scormRegistrations}/${
				environment.scorm.registrationPrefix
					? `${environment.scorm.registrationPrefix}${rid}`
					: rid
			}`,
			{
				includeChildResults: true,
				includeInteractionsAndObjectives: true,
				includeRuntime: true,
			},
			'scorm',
			undefined,
			undefined,
			0
		);
	}

	// public getBookmarkedCOurses(): Observable<MyCourseListInterface> {
	// 	return this.apiService.get(`${}`, null);
	// }

	public captureTestResponse(payload: TestCaptureInterface): Observable<any> {
		return this.apiService.post(`${UrlNames.testResponse}`, payload);
	}

	public getCertificate(
		cid: number,
		isSelfAssigned: boolean,
		isLp: boolean,
		userAssignmentId?: number,
		userCourseHistoryId?: number
	): Observable<any> {
		return this.apiService.get(`${UrlNames.viewCertificate}`, {
			userId: this._userId,
			courseId: cid,
			isLearningPathWay: isLp,
			userAssignmentId: userAssignmentId ?? null,
			userCourseHistoryId: userCourseHistoryId ?? null,
			isSelfAssigned,
		});
	}

	public sendFeedback(payload: SendFeedBackInterface): Observable<any> {
		payload = {
			...payload,
			locationId: this._locationId,
			supervisorId: this.supervisor1,
			supervisor2: this.supervisor2,
			userId: this._userId,
		};
		return this.apiService.post(`${UrlNames.sendFeedback}`, payload);
	}
	public sendRequestSuppport(
		payload: SendFeedBackInterface
	): Observable<any> {
		payload = {
			...payload,
			locationId: this._locationId,
			supervisorId: this.supervisor1,
			supervisor2: this.supervisor2,
			userId: this._userId,
		};
		return this.apiService.post(`${UrlNames.sendRequestSuppport}`, payload);
	}

	public shareCertificate(payload: {
		certificatePath: string;
		fileExtension: string;
		fileName: string;
		receivers: string;
		date: string;
		userId: number;
		course: string;
	}): Observable<any> {
		let _payload = {
			...payload,
			userId: payload.userId ?? this._userId,
		};
		return this.apiService.post(`${UrlNames.shareCertificate}`, _payload);
	}

	public getPEQuestions(): Observable<PESurveyInterface[]> {
		return this.apiService
			.get(`${UrlNames.programEvalutaion}`, null)
			.pipe(map((x) => x['data'][0]['questions']));
	}

	public getTotalUsersWhoCompletedCourse(cid: number): Observable<number> {
		return this.apiService
			.get(`${UrlNames.getTotalUsersWhoCompletedCourse}`, {
				courseId: cid,
			})
			.pipe(map((x) => x['data'][0]['count']));
	}

	public submitPESurveyAnswers(
		payload: PESurveySubmitINterface
	): Observable<any> {
		payload = {
			...payload,
			userId: this._userId,
			locationId: this._locationId,
		};
		return this.apiService.post(`${UrlNames.programEvalutaion}`, payload);
	}

	public updateExtraParamInLocalStorage(
		educationYr?: number,
		hideFutureCourses?: 0 | 1
	): void {
		const extraParams = this.mcExtraParamsFromLS;

		if (extraParams) {
			if (educationYr) {
				extraParams.educationYear = educationYr;
			}
			if (hideFutureCourses !== undefined) {
				extraParams.hideCoursePastDue = hideFutureCourses;
			}
			localStorage.setItem(
				LocalStorageKey.MY_COURSE_EXTRA_PARAMS,
				JSON.stringify(extraParams)
			);
		}
	}

	public saveSCORMCourseTestData(
		payload: SaveSCORMCourseTestDataInterface
	): Observable<any> {
		return this.apiService.post(`${UrlNames.saveSCORMCourseTestData}`, {
			...payload,
			userId: this._userId,
			locationId: this._locationId,
		});
	}

	public loadMyCourseTestQuizData(
		userTestHistoryId: number
	): Observable<any> {
		return this.apiService
			.get(`${UrlNames.loadMyCourseTestQuizData}`, {
				userTestHistoryId,
			})
			.pipe(map((x) => x['data'][0]));
	}
	public loadQuizSummary(
		userTestHistoryId: number,
		userAssignmentId: number
	): Observable<CourseQuizSummaryInterface> {
		return this.apiService
			.get(`${UrlNames.loadQuizSummary}`, {
				userTestHistoryId,
				userAssignmentId,
			})
			.pipe(map((x) => x['data'][0]));
	}
	public loadLpQuizSummary(
		learningPathId: number,
		assignmentId: number,
		userId?: number
	): Observable<any> {
		return this.apiService
			.get(`${UrlNames.loadLpQuizSummary}`, {
				userId: userId ?? this._userId,
				learningPathId,
				assignmentId,
			})
			.pipe(map((x) => x['data'][0]));
	}

	public get mcExtraParamsFromLS(): {
		educationYear: number;
		hideCoursePastDue: 0 | 1;
	} {
		return (
			(JSON.parse(
				localStorage.getItem(LocalStorageKey.MY_COURSE_EXTRA_PARAMS) ??
					null
			) as { educationYear: number; hideCoursePastDue: 0 | 1 }) ?? {
				educationYear: null,
				hideCoursePastDue: null,
			}
		);
	}

	public routeToCourse(
		courseType: 'lp' | 'course',
		parentId: number,
		params: {
			[SlrUrlQueryParams.USER_ASSIGNMENT_ID]?: number;
			[SlrUrlQueryParams.ASSIGNMENT_ID]?: number;
			[SlrUrlQueryParams.TEST_TYPE]?: 'pre-test' | 'post-test';
			[SlrUrlQueryParams.SHOW_CONGRATULATIONS]?: boolean;
			[SlrUrlQueryParams.COURSE_OPENED_FROM]?: 'mc' | 'rc';
		},
		subStep1?: 'test' | 'take-test' | 'take-course' | 'course',
		childId?: number,
		subStep2?: 'take-course' | 'test' | 'take-test',
		grandChildId?: number,
		relativeTo?: boolean
	): void {
		if (relativeTo) {
			this.router.navigate(
				[subStep1, childId, subStep2, grandChildId].filter(
					(x) => x !== undefined
				),
				{
					queryParamsHandling: 'merge',
					queryParams: params,
					relativeTo: this.route,
				}
			);
		} else {
			this.router.navigate(
				[
					'/courses',
					courseType,
					parentId,
					subStep1,
					childId,
					subStep2,
					grandChildId,
				].filter((x) => x !== undefined),
				{
					queryParamsHandling: 'merge',
					queryParams: params,
				}
			);
		}
	}

	/**
	 * This method checks if the logged in user is in orientation mode or not
	 * If the result is true - save the flag in localStorage if not already saved.
	 * if result comes as false and value is true in localStorage, then show popup and clear localStorage value
	 */
	public checkUserOrientationMode(): Observable<boolean> {
		return this.apiService
			.get(`${UrlNames.userOrientationMode}`, {
				userId: this._userId,
			})
			.pipe(map((res) => res['data'][0] == 1));
	}

	public getCertificateNew(
		userId: number,
		courseName: string,
		completionDate: string
	) {
		return this.apiService.get(`${UrlNames.downloadCertificate}`, {
			userId: userId ?? this._userId,
			// username: 'Ankit',
			course: courseName,
			date: completionDate,
		});
	}

	public sendLiveEventMessage(
		liveEventName: string,
		dateTime: string
	): Observable<any> {
		return this.apiService.post(`${UrlNames.sendLiveEventNames}`, {
			liveEventName,
			userDisplayName: this._displayName,
			locationId: this._locationId,
			liveEventDueDate: dateTime,
		});
	}

	public updateRochTestDetails(payload: CobasTestDetailsInterface) {
		const body: CobasTestDetailsInterface = {
			...payload,
			firstName: this._firstName,
			lastName: this._lastName,
			userId: this._userId,
		};
		return this.apiService.post(`${UrlNames.updateRochTestDetails}`, body);
	}
	public updateCourseRusticiFlag(payload: {
		courseId: number;
		rusticiCompletionFlag: 0 | 1;
	}) {
		return this.apiService.post(
			`${UrlNames.updateCourseRusticiFlag}`,
			payload
		);
	}
}
