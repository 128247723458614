import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarItem } from '@syncfusion/ej2-angular-documenteditor';
import {
	BehaviorSubject,
	filter,
	map,
	mergeMap,
	Observable,
	Subject,
	take,
} from 'rxjs';
import { CustomTableColumn } from 'src/app/modules/reports/_models/reports-overview.model';
import { LocationDetail } from 'src/app/modules/reports/_models/survey-summary-report.model';
import { SurveysInterface } from 'src/app/modules/survey/_models/take-survey.interface';
import { CourseInfoCard } from 'src/app/shared/components/course-info-cards/course-info-cards.model';
import { Courses } from 'src/app/shared/models/categories';
import {
	getSelectedLocation,
	getSettingValueById,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { PermissionHelper } from '../helpers/permission-helper.class';
import { LocalStorageKey } from '../models/local-storage-key.enum';
import { UserPrivilageInterface } from '../models/user-privilege.interface';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { SettingsMap } from 'src/app/modules/user-settings/_models/settins-map.enum';

@Injectable({
	providedIn: 'root',
})
export class LocalDataService {
	public autocheckPreviousYear: boolean = false;
	public permissionHelper = new PermissionHelper();
	private educationYearData: { date: number; month: number };
	public _locationId: number = -1;
	public miniSidebarWidth = '6%';
	public expandedSidebarWidth = '20%';
	public modalHeader = '';
	public inactivateAction = 'inactivate';
	public activateAction = 'activate';
	public unassignAction = 'unassign';
	public unassignAssignmentsAction = 'unassignAssignments';
	public removeRecommend = 'remove-recommend';
	public deleteAction = 'delete';
	public unarchiveAction = 'unarchive';
	public pageLimits = [100, 200, 300, 400, 'All'];
	public allContents = 'All';
	public skillNeverExpire = 'Never Expires';
	public id = new Subject<number>();
	private modalOpen = new Subject<boolean>();
	public castModalStatus = this.modalOpen.asObservable();
	public durationRegex = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$';
	public isScorm: boolean = false; // when assigning a course, use this to segregate b/w course type
	public scormLearners: {
		id: string;
		firstName: string;
		lastName: string;
	}[] = [];
	public resourceContentSize = 600 * 1024 * 1024; //600 MB
	public resourcePdfContentSize = 800 * 1024 * 1024; //800 MB
	public resourcePdfContentSizeForSurgeAdmin = 2 * 1024 * 1024 * 1024; //2 GB
	public resourceContentSizeForSurgeAdmin = 3 * 1024 * 1024 * 1024; //3 GB
	public scormCourseSizeForSurgeAdmin = 4 * 1024 * 1024 * 1024;
	public scormCourseSize = 2 * 1024 * 1024 * 1024;
	public selectedUsersToAddSkill = new BehaviorSubject<Array<any>>(null);

	// Order needs to be maintained
	public dashboardInfoCards: Array<CourseInfoCard> = [
		{
			count: 0,
			type: 'COURSES',
			class: 'past-due',
			footerText: 'PAST_DUE',
			redirectTo: '/courses',
			privilegeKey: this.permissionHelper.dashboardModule.MODULE_NAME,
		},
		{
			count: 0,
			type: 'COURSES',
			class: 'coming-due',
			footerText: 'COMING_DUE',
			redirectTo: '/courses',
			privilegeKey: this.permissionHelper.dashboardModule.MODULE_NAME,
		},
		{
			count: 0,
			type: 'SURVEYS_HEADER',
			class: 'to-do',
			footerText: 'SURVEYS_DUE',
			redirectTo: '/surveys',
			privilegeKey: this.permissionHelper.SurveysModule.MODULE_NAME,
		},
		{
			count: 0,
			type: 'SKILLS',
			class: 'approved',
			footerText: 'SKILLS_DUE',
			redirectTo: '/profile',
			fragment: 'skills',
			privilegeKey: this.permissionHelper.dashboardModule.MODULE_NAME,
		},
		{
			count: 0,
			type: 'SKILLS',
			class: 'pending',
			footerText: 'SKILLS_PENDING_APPROVAL',
			redirectTo: '/reports/skills/summary',
			privilegeKey:
				this.permissionHelper.dashboardModule
					.TILE_SKILLS_PENDING_APPROVAL,
		},
	];
	public defaultColors = {
		footerColor: '#dddddd',
		footerFontColor: '#666666',
		headerColor: '#ffffff',
		headerFontColor: '#666666',
	};
	public usualToolbarItems: ToolbarItem[] = [
		'Undo',
		'Redo',
		'Comments',
		'Image',
		'Table',
		'Hyperlink',
		'Bookmark',
		'TableOfContents',
		'Header',
		'Footer',
		'PageSetup',
		'PageNumber',
		'Break',
		'Find',
		'LocalClipboard',
		'RestrictEditing',
	];

	public layoutToolbarItems: ToolbarItem[] = [
		'Undo',
		'Redo',
		'Header',
		'Footer',
		'Table',
		'Image',
		'PageNumber',
		'PageSetup',
	];

	public courseStatuses = [
		{
			id: 1,
			status: 'Active',
			translationKey: 'ACTIVE',
		},
		{
			id: 8,
			status: 'Assigned',
			translationKey: 'ASSIGNED',
		},
		{
			id: 2,
			status: 'Inactive',
			translationKey: 'INACTIVE',
		},
		{
			id: 6,
			status: 'New Courses (30 days)',
			translationKey: 'NEW_COURSE_30D',
		},
		{
			id: 7,
			status: 'New Courses (1 year)',
			translationKey: 'NEW_COURSE_1Y',
		},
		{
			id: 4,
			status: 'Hidden',
			translationKey: 'HIDDEN',
		},
		{
			id: 5,
			status: 'Recommended',
			translationKey: 'RECOMMENDED',
		},
		{
			id: 3,
			status: 'Archived',
			translationKey: 'ARCHIVED',
		},
	];

	public docsStatuses = [
		{
			id: 1,
			status: 'Active',
			translationKey: 'ACTIVE',
		},
		{
			id: 8,
			status: 'Assigned',
			translationKey: 'ASSIGNED',
		},
		{
			id: 2,
			status: 'Inactive',
			translationKey: 'INACTIVE',
		},
		{
			id: 4,
			status: 'Hidden',
			translationKey: 'HIDDEN',
		},
		{
			id: 5,
			status: 'Recommended',
			translationKey: 'RECOMMENDED',
		},
		{
			id: 3,
			status: 'Archived',
			translationKey: 'ARCHIVED',
		},
	];

	public selectedCourses: Courses[] = [];
	public selectedAssignments = [];
	public selectedSurveys: SurveysInterface[] = [];
	public Liststatus = [
		{
			id: 1,
			status: 'Show Incomplete',
		},
		{
			id: 2,
			status: 'Show Completed',
		},
		{
			id: 3,
			status: 'Show Started',
		},
		{
			id: 4,
			status: 'Show All',
		},
	];
	public attempts = [0, 1, 2, 3, 4, 5];

	public my_courses = [
		{
			userId: 1,
			courseId: 1,
			courseName: 'Course1',
			courseIntroduction: 'This is course 1',
			contentType: 'pdf',
			contentName:
				'12 - educational module - Continence Management Program',
			reference: 'refer1',
			duration: '00:01:36',
			l: 1,
			educationGroupName: 'group1',
			dueDate: '10-03-2022',
			isMandatory: 1,
			isRecommended: 1,
			filePath: 'customer_files/content/main/videos',
			fileLocation: 'internal',
			courseProgress: '20% completed',
			courseStartDate: 'June 14, 2021',
			hasTest: true,
			hasMicroLearning: false,
			hasPreTest: true,
			hasPostTest: false,
			minimumPassingRate: 70,
		},
		{
			userId: 1,
			courseId: 3,
			courseName: 'Course3',
			courseIntroduction: 'This is course 3',
			contentType: 'mp4',
			contentName: 'Covid course youtube course',
			reference: 'refer3',
			duration: '00:10:30',
			educationGroupId: 1,
			educationGroupName: 'group1',
			dueDate: '12-03-2022',
			isMandatory: 1,
			isRecommended: 0,
			filePath: 'https://www.youtube.com/embed/ijgTv8HRLlc',
			fileLocation: 'external',
			courseProgress: '20% completed',
			courseStartDate: '04-03-2022',
			hasTest: true,
			hasMicroLearning: false,
			hasPreTest: false,
			hasPostTest: true,
			minimumPassingRate: 80,
		},
	];
	public my_qrm = [
		{
			userId: 60,
			userName: 'Jazz',
			qrmId: 1,
			qrmName: 'PPE Audit',
			qrmIntroduction: 'This is qrm 1',
			contentType: 'pdf',
			contentName: 'IPAC Audit',
			reference: 'refer1',
			scheduleName: 'PPE audit Test',
			dueDate: '10/03/2022',
			qrmProgress: '20% completed',
			qrmStartDate: 'June 14, 2021',
			isMandatory: 1,
			isOndemand: 0,
			status: 'Not Started',
			userGroup: 'Ipac Staff',
			category_id: 4,
		},
		{
			userId: 60,
			userName: 'Jazz',
			qrmId: 3,
			qrmName: 'Hand Hygiene Audit',
			qrmIntroduction: 'This is qrm 2',
			contentType: 'mp4',
			contentName: 'IPAC Audit',
			reference: 'refer3',
			scheduleName: 'Hand audit Test',
			dueDate: '09/01/2022',
			qrmProgress: '60% completed',
			qrmStartDate: '04-03-2022',
			isMandatory: 1,
			isOndemand: 0,
			status: 'Started',
			userGroup: 'All Staff',
			category_id: 4,
		},
		{
			userId: 60,
			userName: 'Jazz',
			qrmId: 4,
			qrmName: 'Staff care audit',
			qrmIntroduction: 'This is qrm 3',
			contentType: 'mp4',
			contentName: 'IPAC Audit',
			reference: 'refer3',
			scheduleName: 'Staff care May19',
			dueDate: '09/01/2022',
			qrmProgress: '20% completed',
			qrmStartDate: '04-03-2022',
			isMandatory: 1,
			isOndemand: 0,
			status: 'Started',
			userGroup: 'All Staff',
			category_id: 1,
		},
		{
			userId: 60,
			userName: 'Jazz',
			qrmId: 5,
			qrmName: 'Smoke Room Audit',
			qrmIntroduction: 'This is qrm 4',
			contentType: 'mp4',
			contentName: 'IPAC Audit',
			reference: 'refer3',
			scheduleName: 'Smoke room May 20',
			dueDate: '09/01/2022',
			qrmProgress: '70% completed',
			qrmStartDate: '04-03-2022',
			isMandatory: 1,
			isOndemand: 0,
			status: 'Started',
			userGroup: 'All Staff',
			category_id: 4,
		},
	];
	public myqrm_category = [
		{
			category_id: 1,
			category_name: 'Dietary',
			client_code: 'demo',
			categoryCount: '1',
			schedule: '1',
		},
		{
			category_id: 2,
			category_name: 'Nursing - Resident Care',
			client_code: 'demo',
			categoryCount: '0',
			schedule: '0',
		},
		{
			category_id: 3,
			category_name: 'Job Performance',
			client_code: 'demo',
			categoryCount: '0',
			schedule: '0',
		},
		{
			category_id: 4,
			category_name: 'Infection Prevention and Control ',
			client_code: 'demo',
			categoryCount: '4',
			schedule: '1',
		},
	];
	public my_action = [
		{
			userId: 1,
			actionId: 1,
			actionName: 'test',
			status: 'Pending',
			date: '04-03-2022',
			type: 'General Action',
			notification: 'Enable',
			userName: 'Test',
		},
		{
			userId: 1,
			actionId: 2,
			actionName: 'test',
			status: 'Complete',
			date: '04-03-2022',
			type: 'General Action',
			notification: 'Enable',
			userName: 'Test',
		},
	];

	public my_history = [
		{
			userId: 1,
			courseId: 4,
			courseName: 'COVID-19 Guidence',
			courseIntroduction:
				'This course regarding the COVID precautions and guidence to follow',
			contentType: 'mp4',
			contentName: '1.3M Foam Adhesive Dressing',
			reference: '',
			duration: '03:01:36',
			dueDate: '21-02-2022',
			isMandatory: 1,
			isRecommended: 0,
			isBookMarked: 0,
			filePath: 'customer_files/content/main/videos',
			fileLocation: 'internal',
			courseStartDate: '03-03-2022',
			completedDate: '07-03-2022',
			preTestScore: '73',
			postTestScore: '87',
			timeInCourse: '00:30:10',
			certificatePath:
				'customer_files/files/manuals/all_on/Public Health Ontario/UTI_Presentation_Orientation_Education_Program.pdf',
			minimumPassingRate: 80,
			courseProgress: '100% completed',
			firstName: 'Shaik',
			lastName: 'Basha',
			displayName: 'Chand',
		},
		{
			userId: 1,
			courseId: 5,
			courseName: 'Course for surgery',
			courseIntroduction: 'This course regarding the surgery',
			contentType: 'flv',
			contentName: '1.3M Foam Adhesive Dressing',
			reference: '',
			duration: '03:01:36',
			dueDate: '21-02-2022',
			isMandatory: 0,
			isRecommended: 1,
			isBookMarked: 0,
			filePath: 'customer_files/content/main/videos',
			fileLocation: 'internal',
			courseStartDate: '03-03-2022',
			completedDate: '06-03-2022',
			preTestScore: '80',
			postTestScore: null,
			timeInCourse: '00:28:19',
			certificatePath:
				'customer_files/files/manuals/all_on/Public Health Ontario/UTI_Presentation_Orientation_Education_Program.pdf',
			minimumPassingRate: 80,
			courseProgress: '100% completed',
			firstName: 'Shaik',
			lastName: 'Basha',
			displayName: 'Chand',
		},
	];
	public resourceListParams = this.getResourceParams();
	public getResourceParams() {
		return {
			module: 1,
			status: 1,
			lang: 1,
			userid: this.getUserId(),
			locationId: this.getLocationId(),
		};
	}
	public freequencies = [
		{
			name: 'EVERY_T1_MONTH',
			labelValue: 1,
			value: 1,
		},
		{
			name: 'EVERY_T1_MONTHS',
			labelValue: 3,
			value: 3,
		},
		{
			name: 'EVERY_T1_MONTHS',
			labelValue: 6,
			value: 6,
		},
		{
			name: 'EVERY_T1_YEAR',
			value: 12,
			labelValue: 1,
		},
		{
			name: 'EVERY_T1_YEAR',
			value: 24,
			labelValue: 2,
		},
		{
			name: 'EVERY_T1_YEAR',
			value: 36,
			labelValue: 3,
		},
	];

	public recurringAudit = [
		{
			name: 'EVERY_WEEK',
			id: 7,
		},
		{
			name: 'EVERY_MONTH',
			id: 30,
		},
		{
			name: 'EVERY_6_WEEKS',
			id: 42,
		},
		{
			name: 'EVERY_2_MONTHS',
			id: 60,
		},
		{
			name: 'EVERY_3_MONTHS',
			id: 90,
		},
		{
			name: 'EVERY_6_MONTHS',
			id: 180,
		},
		{
			name: 'EVERY_YEAR',
			id: 365,
		},
		{
			name: 'EVERY_DAY',
			id: 1,
		},
	];

	public languages = [
		{
			name: 'English',
			code: 'en',
		},
		{
			name: 'Spanish',
			code: 'es',
		},
		{
			name: 'Spanish',
			code: 'da',
		},
	];
	public durations = [
		{
			name: 'EVERY_MONTH',
			labelValue: 1,
			value: 1,
		},
		{
			name: 'EVERY_YEAR',
			value: 2,
			labelValue: 1,
		},
		{
			name: 'SET_DATE',
			value: 3,
			labelValue: 1,
		},
	];
	public optionTypeData = [
		{
			type: 1,
			name: 'Radio Buttons',
		},
		{
			type: 2,
			name: 'Checkboxes',
		},

		{
			type: 3,
			name: 'Text',
		},
	];

	public surveyQuestionType = [
		{
			type: 1,
			name: 'Checkbox',
		},
		{
			type: 7,
			name: 'Date',
		},
		{
			type: 6,
			name: 'Date and time',
		},

		{
			type: 2,
			name: 'Radio Button',
		},
		{
			type: 4,
			name: 'Slider',
		},

		{
			type: 3,
			name: 'Text',
		},
	];

	public qrmQuestionType = [
		{
			type: 1,
			name: 'Radio',
		},
		{
			type: 2,
			name: 'Checkbox',
		},
		{
			type: 3,
			name: 'Textbox',
		},

		{
			type: 4,
			name: 'Number',
		},

		{
			type: 5,
			name: 'Date',
		},

		{
			type: 6,
			name: 'Slider',
		},
	];

	public optionsBasedOnRadioType = [
		{
			type: 1,
			name: 'Yes-No-N/A',
		},
		{
			type: 2,
			name: 'True-False-N/A',
		},
		{
			type: 3,
			name: 'Met-Partially Met-Not Met-Not Applicable',
		},
	];

	// live event JSON start
	public avail_assigned_event_row = [
		{
			id: 1,
			eventname: 'Surge Learning',
			description: 'Nullam vitae justo eu turpis ultrices pulvinar	',
			author: 'Gemma Zhang',
			datetime: '03-17-2022 10:22:35 AM',
		},
		{
			id: 2,
			eventname: 'WHMIS Part 1 and Part 2',
			description: 'Maecenas facilisis urna metus, ac feugiat dolor	',
			author: 'Eugene Berry	',
			datetime: '03-17-2022 10:22:35 AM',
		},
		{
			id: 3,
			eventname: 'Orientation 2021',
			description: 'Nunc pretium urna arcu, quis condimentum',
			author: 'Annabella Gutierrez',
			datetime: '03-22-2022 04:54:12 PM',
		},
		{
			id: 4,
			eventname: 'ABC Angela Sample',
			description: 'Phasellus varius tellus id massa pharetra consequat	',
			author: 'Conah Macfarlane',
			datetime: '03-26-2022 06:45:21 PM',
		},
		{
			id: 5,
			eventname: 'Consectetur adipiscing elit',
			description: 'Quisque sed ligula laoreet, commodo ex nec	',
			author: 'Blessing Charles',
			datetime: '03-30-2022 11:58:36 AM',
		},
	];
	public avail_open_event_row = [
		{
			id: 1,
			eventname: 'Surge Learning',
			description: 'Nullam vitae justo eu turpis ultrices pulvinar	',
			author: 'Gemma Zhang',
			datetime: '03-17-2022 10:22:35 AM',
		},
		{
			id: 2,
			eventname: 'WHMIS Part 1 and Part 2',
			description: 'Maecenas facilisis urna metus, ac feugiat dolor	',
			author: 'Eugene Berry	',
			datetime: '03-17-2022 10:22:35 AM',
		},
		{
			id: 3,
			eventname: 'Orientation 2021',
			description: 'Nunc pretium urna arcu, quis condimentum',
			author: 'Annabella Gutierrez',
			datetime: '03-22-2022 04:54:12 PM',
		},
	];
	public live_event_row = [
		{
			id: 1,
			eventname: 'Surge Learning',
			description: ' Nullam vitae justo eu ',
			author: 'Gemma Zhang',
			Datetime1stSession: '03-17-2022 10:22:35 AM ',
			dateentered: ' 03-17-2022 ',
			completed: '0.0',
			sectiondata: [
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
			],
		},
		{
			id: 2,
			eventname: 'Surge Learning',
			description: ' Nullam vitae justo eu ',
			author: 'Gemma Zhang',
			Datetime1stSession: '03-17-2022 10:22:35 AM ',
			dateentered: ' 03-17-2022 ',
			completed: '0.0',
			sectiondata: [
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
			],
		},
		{
			id: 3,
			eventname: 'Surge Learning',
			description: ' Nullam vitae justo eu ',
			author: 'Gemma Zhang',
			Datetime1stSession: '03-17-2022 10:22:35 AM ',
			dateentered: ' 03-17-2022 ',
			completed: '0.0',
			sectiondata: [
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
				{
					id: 1,
					date: 'Friday, 25 March 2022',
					timeduration: '09:30 - 10:30',
					location: 'https://surgelearning.ca/j/9876543210',
				},
			],
		},
	];

	public skillTypeData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'SELF_APPROVED',
		},
		{
			id: 2,
			name: 'SUPERVISOR_APPROVED',
		},
	];
	public renewalTypeData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'USE_EXPIRY',
		},
		{
			id: 2,
			name: 'NEVER_EXPIRES',
		},
	];
	public useCompetencyData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'YES',
		},
		{
			id: 0,
			name: 'NO',
		},
	];

	public defaultRenewalData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'T1_YEAR',
		},
		{
			id: 2,
			name: 'T1_YEAR',
		},
		{
			id: 3,
			name: 'T1_YEAR',
		},
	];

	public newRenewalData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'T1_YEAR',
		},
		{
			id: 2,
			name: 'T1_YEAR',
		},
		{
			id: 3,
			name: 'T1_YEAR',
		},
	];

	// live event JSON end

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private sharedStore: Store<SharedState>,
		private translate: TranslateService
	) {
		this.markPreviousYearCheckbox();
	}
	public getRouteChange() {
		this.getLocationObs();
		this.getRequiredDates();
		return this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.pipe(map(() => this.activatedRoute))
			.pipe(
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				})
			)
			.pipe(filter((route) => route.outlet === 'primary'))
			.pipe(mergeMap((route) => route.data));
	}

	public updateModalStatus(input: boolean): void {
		this.modalOpen.next(input);
	}
	public datelist = [
		{
			id: 1,
			status: 'March -2022',
		},
		{
			id: 2,
			status: 'April -2022',
		},
		{
			id: 3,
			status: 'May -2022',
		},
	];
	public formatDate(date: any): string {
		const day = `${date.day}`.padStart(2, '0');
		const month = `${date.month}`.padStart(2, '0');
		return `${day}-${month}-${date.year}`;
	}
	public getDateFromDate(date: Date): string {
		const day = `${date.getDate()}`.padStart(2, '0');
		const month = `${date.getMonth() + 1}`.padStart(2, '0');
		return `${day}-${month}-${date.getFullYear()}`;
	}
	public getUserId(): number {
		const privileges = localStorage.getItem('privileges');
		if (privileges) {
			return JSON.parse(privileges).userId;
		}
		return -1;
	}

	public getLearner(): { id: string; firstName: string; lastName: string } {
		const privileges = localStorage.getItem('privileges');
		if (!privileges) {
			return null;
		}
		const _privileges = JSON.parse(privileges) as UserPrivilageInterface;
		return {
			id: _privileges.userId.toString(),
			firstName: _privileges.firstName ?? '',
			lastName: _privileges.lastName ?? '',
		};
	}

	getLocationObs() {
		this.sharedStore.pipe(select(getSelectedLocation), take(1)).subscribe({
			next: (res) => {
				this._locationId = res.location.id;
			},
		});
	}

	public getLocationId(): number {
		const selectedLocation = localStorage.getItem('selectedLocation');
		if (selectedLocation) {
			return JSON.parse(selectedLocation).id;
		}
		return -1;
	}

	public getUserClasses(): Array<string> {
		let userClassDetails = [];
		const privileges = localStorage.getItem('privileges');
		if (privileges) {
			userClassDetails.push(JSON.parse(privileges).userClassName);
		}
		return userClassDetails;
	}

	public getUserClasseId(): number {
		let userClassId;
		const privileges = localStorage.getItem('privileges');
		if (privileges) {
			userClassId = JSON.parse(privileges).userClassId;
		}
		return userClassId;
	}

	public getUserClasseOrder(): number {
		let userClassOrder = 5;
		const privileges = localStorage.getItem('privileges');
		if (privileges) {
			userClassOrder = JSON.parse(privileges).displayOrder;
		}
		return userClassOrder;
	}

	public getSiteCode(): string {
		let siteCode = '';
		const selectedLocation = localStorage.getItem('selectedLocation');
		if (selectedLocation) {
			siteCode = JSON.parse(selectedLocation).sitecode;
		}
		return siteCode;
	}

	public getSelectedLocation(): string {
		let location;
		const selectedLocation = localStorage.getItem('selectedLocation');
		if (selectedLocation) {
			location = JSON.parse(selectedLocation);
		}
		return location;
	}

	public getSeconds(duration: string): number {
		if (!duration) {
			return 0;
		}
		const hms = duration;
		const [hours, minutes, seconds] = hms.split(':');
		return +hours * 60 * 60 + +minutes * 60 + +seconds;
	}
	public searchReportTable(
		searchText: string,
		masterTable: Array<any>,
		columns: Array<CustomTableColumn>,
		columnSpecific: CustomTableColumn = null
	) {
		if (searchText && !columnSpecific) {
			return masterTable.filter((row) =>
				columns
					.filter((column) => column.isShown)
					.some((column) =>
						row[column.bindKey]
							?.toString()
							.toLowerCase()
							.includes(searchText.toLowerCase())
					)
			);
		}
		if (searchText && columnSpecific) {
			return masterTable.filter((row) =>
				row[columnSpecific.bindKey]
					?.toString()
					.toLowerCase()
					.includes(searchText.toLowerCase())
			);
		}
		return masterTable;
	}
	public getUnreferredArray(array: any[]): any[] {
		return JSON.parse(JSON.stringify(array));
	}
	public getUnreferredObject(obj: Object): any {
		return JSON.parse(JSON.stringify(obj));
	}
	public getStringifiedArray(array: any[], key: string) {
		return array.map((row) => row[key]).join(', ');
	}
	public isSurgeAdmin(): boolean {
		return (
			JSON.parse(
				localStorage.getItem('privileges')
			)?.userClassName.toLowerCase() === 'surge administrator'
		);
	}
	public isDeptSupervisor(): boolean {
		return (
			JSON.parse(
				localStorage.getItem('privileges')
			)?.userClassName.toLowerCase() === 'department supervisor'
		);
	}
	public getToday(): Date {
		return new Date();
	}
	public getCurrentLocationType(): string {
		return localStorage.getItem(LocalStorageKey.CURRENT_LOCATION_TYPE);
	}
	public getFilterLocations() {
		const locationData = JSON.parse(localStorage.getItem('locationData'));
		const allLocations: LocationDetail[] = [];
		if (locationData.grandParent) {
			const grandParent = locationData.grandParent;
			allLocations.push({
				id: grandParent.grandParentLocationId,
				name: grandParent.grandParentLocationName,
				siteCode: grandParent.siteCode,
			});
			allLocations.push(...this.getParentLocations(grandParent.parents));
			grandParent.parents.forEach((parent) => {
				allLocations.push(...this.getchildLocations(parent.childs));
			});
		} else if (locationData.parent) {
			allLocations.push(
				...this.getParentLocations([locationData.parent])
			);
			allLocations.push(
				...this.getchildLocations(locationData.parent.childs)
			);
		} else if (locationData.child) {
			allLocations.push(...this.getchildLocations([locationData.child]));
		}
		return allLocations;
	}
	private getParentLocations(parents): LocationDetail[] {
		return parents.map((parent) => {
			return {
				id: parent.parentLocationId,
				name: parent.parentLocationName,
				siteCode: parent.siteCode,
			};
		});
	}
	private getchildLocations(
		children: {
			locationId: number;
			locationName: string;
			siteCode: string;
		}[]
	): LocationDetail[] {
		return children.map((child) => {
			return {
				id: child.locationId,
				name: child.locationName,
				siteCode: child.siteCode,
			};
		});
	}
	public returnTranslatedColumns(columns: string[]): Observable<any> {
		return this.translate.get(columns);
	}
	public getReportFormattedDate(date: Date): string {
		return `${date.getFullYear()}-${(date.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
	}
	public paginateArray(
		masterArray: any[],
		limit: any,
		offset: number
	): any[] {
		return limit === this.allContents
			? masterArray
			: masterArray.slice(offset, offset + limit);
	}
	get specificYears() {
		const specificYears: number[] = [];
		const currentYear = new Date().getFullYear();
		for (let i = 0; i < 5; i++) {
			specificYears.push(currentYear + i);
		}
		return specificYears;
	}

	getTranslatedString(key: string) {
		this.translate.get(key).subscribe({
			next: (res) => {
				console.log('res: ', res);
				return res;
			},
			error: () => {
				return key;
			},
		});
	}
	public sortGroups(groups: { groupName: string }[]): any[] {
		return groups.sort((grp1, grp2) =>
			grp1.groupName > grp2.groupName ? 1 : -1
		);
	}

	public sortStrings(
		arrayInput,
		sortKey,
		sortDir: 'asc' | 'desc' = 'asc'
	): any[] {
		return arrayInput?.sort((first, second) =>
			first[sortKey]?.toString()?.toLowerCase() <
			second[sortKey]?.toString()?.toLowerCase()
				? sortDir === 'asc'
					? -1
					: 1
				: sortDir === 'asc'
				? 1
				: -1
		);
	}

	get educationYears(): number[] {
		let currentYear = +new Date().getFullYear;
		return [
			currentYear - 3,
			currentYear - 2,
			currentYear - 1,
			currentYear,
			currentYear + 1,
			currentYear + 2,
			currentYear + 3,
		];
	}

	public addTranslatedNameToObject(
		obj: Array<any>,
		keyToBeTranslated: string,
		translatedKeyName: string = 'translatedKeyValue'
	): Array<any> {
		const keyValueHolder = new BehaviorSubject<string>(null);
		return obj.map((element) => {
			this.translate
				.get(element[keyToBeTranslated])
				.pipe(take(1))
				.subscribe({
					next: (res) => {
						keyValueHolder.next(res);
					},
				});
			return {
				...element,
				[translatedKeyName]: keyValueHolder.value,
			};
		});
	}
	public get currentEducationYear(): number {
		const today = this.getToday();
		const year = today.getFullYear();
		const { startDate } = this.getEducationYearDates(year);
		return startDate > today ? year - 1 : year;
	}
	public getEducationYearDates(educationYear: number): {
		startDate: Date;
		endDate: Date;
	} {
		const { date, month } = this.educationYearData;
		const startDate = new Date(educationYear, month - 1, date);
		const endDate = new Date(educationYear, month - 1, date);
		const oneDayInMillis = 24 * 60 * 60 * 1000;
		endDate.setFullYear(endDate.getFullYear() + 1);
		return {
			startDate,
			endDate: new Date(endDate.getTime() - oneDayInMillis),
		};
	}
	public getRequiredDates(educationalYear?: number): void {
		this.sharedStore
			.pipe(
				select(getSettingValueById(SettingsMap.client_education_year)),
				filter((value) => !!value),
				take(1)
			)
			.subscribe({
				next: ([value]) => {
					const [month, date] = value.split('-');
					this.educationYearData = {
						date,
						month,
					};
				},
			});
	}
	public markPreviousYearCheckbox(): void {
		this.sharedStore
			.pipe(
				select(
					getSettingValueById(
						SettingsMap.my_courses_check_previous_year
					)
				),
				filter((value) => !!value),
				take(1),
				map(([checkPreviousCourses]) => {
					this.autocheckPreviousYear = checkPreviousCourses
						? true
						: false;
				})
			)
			.subscribe();
	}
}
