import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { SLRPaginatorDirective } from 'src/app/core/directives/slr-paginator.derective';
import { NgPaginationComponent } from 'src/app/shared/ng-pagination/ng-pagination.component';

@Component({
	selector: 'slr-table',
	templateUrl: './slr-table.component.html',
	styleUrls: ['./slr-table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class SlrTableComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild(NgPaginationComponent, { static: false })
	ngPagination: NgPaginationComponent;

	@ViewChild(MatSort, { static: true }) matSort: MatSort;

	@Input() isPageable: boolean = false;
	@Input() isSelectable: boolean = false;
	@Input() isSortable: boolean = false;
	@Input() tableColumns: TableColumn[];
	@Input() rowActionIcon: Array<string>;
	@Input() paginationSizes: number[] = [100, 200, 300, 400];
	@Input() defaultPageSize = 100;
	@Input() hasLegend: boolean = false;
	@Input() legendColumnName: string;
	@Input() legendData: Array<LegendData>;
	@Optional() @Input() public paginationType: 'internal' | 'external';
	@Optional() @Input() public themeClass: 'lms' | 'policy' | 'qrm' = 'lms';
	@Input() actionButtons: TableActionButtonInterface[] = [];
	@Input() activeSort: string;
	@Input() sortDirection: 'asc' | 'desc' = 'asc';
	@Input() set tableData(data: any[]) {
		this._tableData = data;
		this.sortedData = data?.slice();
		this.initDisplayedColumn(data);
		this.cdr.detectChanges();
	}
	public _tableData: any[];
	@Input() isStickyHead = false;

	@Output() rowEditAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowCloseAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowChecked: EventEmitter<any[]> = new EventEmitter();
	@Output() rowAction: EventEmitter<any> = new EventEmitter();
	@Output() pageChange: EventEmitter<any> = new EventEmitter();

	public maxall: number = 100;
	public tableDataSource = new MatTableDataSource<any>([]);
	public displayedColumns: string[];
	public selection = new SelectionModel<any>(true);
	private sortedData: any[];
	private unsubscriber$ = new Subject<void>();

	constructor(
		public cdr: ChangeDetectorRef, // @Inject(DataPropertyGetterPipe) private dataPropGetter: DataPropertyGetterPipe
		public translateService: TranslateService
	) {
		this.tableDataSource = new MatTableDataSource(this.sortedData);
	}

	ngOnInit(): void {
		if (!this.activeSort) {
			this.activeSort = this.tableColumns[0].dataKey;
		}
		this.initColumns();
		this.listenSort();
	}

	ngAfterViewInit(): void {
		this.tableDataSource.paginator = this.ngPagination?.matPaginator;
		if (this.isPageable && this._tableData.length > 0) this.initMatIntl();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['tableColumns'] && !changes['tableColumns'].firstChange) {
			this.initColumns(true);
		}

		if (
			(!changes['tableData'] ||
				!changes['tableData'].previousValue ||
				!changes['tableData'].previousValue.length) &&
			!changes['tableData'].currentValue.length
		)
			return;
		if (changes['hasLegend']) {
			this.initColumns(true);
		}
		if (this.isSortable) {
			this.sortedData = changes['tableData'].currentValue.slice();
			this.sortColumn({
				column: {
					name: '',
					dataKey: this.activeSort,
				},
			});
			this.tableDataSource = new MatTableDataSource<any>(this.sortedData);
		}
		setTimeout(() => {
			this.ngPagination.pagdir &&
				this.ngPagination.pagdir.initPaginator();
		}, 10);
		this.tableDataSource.paginator = this.ngPagination?.matPaginator;
	}

	private initMatIntl(): void {
		// this.translateService
		// 	.get('ITEMS_PER_PAGE')
		// 	.pipe(
		// 		withLatestFrom(this.translateService.get('OF')),
		// 		takeUntil(this.unsubscriber$)
		// 	)
		// 	.subscribe({
		// 		next: ([perPage, of]) => {
		// 			this.ngPagination.matPaginator._intl.itemsPerPageLabel =
		// 				perPage;
		// 			this.ngPagination.matPaginator._intl.getRangeLabel = (
		// 				page: number,
		// 				pageSize: number,
		// 				length: number
		// 			) => {
		// 				if (length === 0 || pageSize === 0) {
		// 					return `0 ${of} ${length}`;
		// 				}
		// 				length = Math.max(length, 0);
		// 				const startIndex = page * pageSize;
		// 				const endIndex =
		// 					startIndex < length
		// 						? Math.min(startIndex + pageSize, length)
		// 						: startIndex + pageSize;
		// 				return `${
		// 					startIndex + 1
		// 				} - ${endIndex} ${of} ${length}`;
		// 			};
		// 		},
		// 	});
	}
	private listenSort(): void {
		this.matSort.sortChange.subscribe((sort: Sort) => {
			this.ngPagination.matPaginator.pageIndex = 0;
		});
	}

	public onPageChange(event: any): void {
		console.log(event);
		if (this.paginationType === 'external') {
			this.pageChange.emit(event);
		}
	}
	public initDisplayedColumn(data: any): void {
		this.tableDataSource = new MatTableDataSource<any>(data);
	}

	public initColumns(fromChanges: boolean = false): void {
		this.displayedColumns = this.tableColumns
			.filter((x) => x.isVisible)
			.map((tableColumn: TableColumn) => tableColumn.name);

		if (this.isSelectable) {
			this.displayedColumns = ['select', ...this.displayedColumns];
		}

		if (this.rowActionIcon) {
			this.displayedColumns = [
				...this.displayedColumns,
				...this.rowActionIcon,
			];
		}
		if (this.actionButtons && this.actionButtons.length > 0) {
			this.displayedColumns = [
				...this.displayedColumns,
				// ...this.actionButtons.map((action) => action.icon),
				'action',
			];
		}
		// if (this.hasLegend) {
		// 	this.displayedColumns = ['legend', ...this.displayedColumns];
		// }
		// if (!this.activeSort) {
		// 	this.activeSort = this.tableColumns[0].dataKey;
		// }
		!fromChanges &&
			this.sortColumn({
				column: {
					name: '',
					dataKey: this.activeSort ?? this.tableColumns[0].dataKey,
				},
			});
	}

	public emitRowAction(row: any, action: string): void {
		if (action === 'close') this.rowCloseAction.emit(row);
		else if (action === 'edit') this.rowEditAction.emit(row);
	}

	public toggleSelection(element: any, event, index): void {
		this.selection.toggle(element);
		this.rowChecked.emit(this.selection.selected);
	}

	getPageSizeOptions(): number[] {
		if (this.tableDataSource.paginator?.length > this.maxall)
			return [
				...this.paginationSizes,
				this.tableDataSource.paginator?.length,
			];
		else return [...this.paginationSizes, this.maxall];
	}
	public emitAction(row, action) {
		this.rowAction.next({ action, row });
	}

	public sortColumn(event: any, fromUI: boolean = false): void {
		if (fromUI) {
			// Data is beign sorted by the user
			if (this.activeSort === event.column.dataKey) {
				// sorting same column hence do opposite sort
				this.sortDirection =
					this.sortDirection === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortDirection = 'asc';
				this.activeSort = event.column.dataKey;
			}
		} else {
			this.sortDirection = 'asc';
		}

		if (this.isSortable) {
			this.sortedData.sort((a, b) => {
				const key = event.column.dataKey;
				const column = this.tableColumns.find(
					(col) => col.dataKey === key
				);

				// Primary sorting based on the primary key
				let primarySortValue = this.getSortValue(a[key], b[key]);

				// Secondary sorting based on the secondary sort key
				if (column?.secondarySortKey) {
					if (primarySortValue === 0) {
						primarySortValue = this.getSortValue(
							a[column?.secondarySortKey],
							b[column?.secondarySortKey]
						);
					}
				}
				return this.sortDirection === 'asc'
					? primarySortValue
					: -primarySortValue;
			});
			this.tableDataSource.data = this.sortedData;
		}
	}

	private getSortValue(a: any, b: any): any {
		if (a == '' || a == null) a = 'zzzz';
		if (b == '' || b == null) b = 'zzzz';

		return a
			.toString()
			.toLowerCase()
			.localeCompare(b.toString().toLowerCase());
	}

	public getLink(linkTemplate: string, rowItem: any): string {
		for (const key in rowItem) {
			if (rowItem.hasOwnProperty(key)) {
				linkTemplate = linkTemplate.replace(`{{${key}}}`, rowItem[key]);
			}
		}
		return linkTemplate;
	}

	public getColspan(element: any, column: TableColumn): number {
		if (column.mergeColumns && column.colspanCondition(element)) {
			return column.mergeColumns.length;
		}
		return 1;
	}
}

export interface TableColumn {
	name: string;
	dataKey: string;
	isVisible?: boolean;
	icon?: any;
	icons?: Array<TableIconInterface>;
	clickAction?: boolean;
	clickActionType?: string;
	position?: 'right' | 'left';
	isSortable?: boolean;
	isFixed?: boolean;
	isPercentage?: boolean;
	tooltip?: {
		text: string;
		length: number;
	};
	noWrap?: boolean;
	breakWord?: boolean;
	mergeColumns?: string[];
	mergeWithPrevious?: (element: any) => boolean;
	colspanCondition?: (element: any) => boolean;
	secondarySortKey?: string;
	mergedText?: string;
	isDate?: boolean;
}

export interface TableIconInterface {
	condition: {
		lhs: any;
		rhs: any;
		op: any;
	};
	innerHtml?: string;
	dataKey?: string;
	tooltip?: string;
	iconClass: string[];
	el: any;
}
export interface LegendData {
	id: number;
	name: string;
	color: string;
	accentColor?: string;
}

export interface TableActionButtonInterface {
	icon: string;
	tooltip: string;
	class?: string;
	url?: string;
	queryParams?: any;
	sticky?: boolean;
	condition?: {
		lhs: any;
		rhs: any;
		op: any;
	};
	conditions?: {
		lhs: any;
		rhs: any;
		op: any;
	}[];
}
