import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	saveSessionExpiryTime,
	saveToken,
} from 'src/app/modules/authentication/_state/authentication.action';
import { AuthenticationState } from 'src/app/modules/authentication/_state/authentication.reducer';
import { LocalStorageKey } from '../models/local-storage-key.enum';
import { LoginToekInterface } from '../models/user-details.interface';
import { EncryptionService } from './encryption.service';
import { BaseWebApiService } from './_base-web-api.service';
import { UrlNames } from './urlProfiler';
import { map, mergeMap } from 'rxjs';
import { AuthService } from 'src/app/modules/authentication/_services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private _token: LoginToekInterface;
	private _sessionExpiryTime: number;
	constructor(
		private authStore: Store<AuthenticationState>,
		private encryptionService: EncryptionService,
		private apiService: BaseWebApiService,
		private authservice: AuthService
	) {}
	public get token(): LoginToekInterface {
		const _token = localStorage.getItem(LocalStorageKey.TOKEN);
		if (!_token) return undefined;
		if (_token == 'undefined') return {} as LoginToekInterface;
		this._token = this.getDecodedToken(_token);
		return this._token;
	}
	public get sessionExipryTime(): number {
		const _sessionExpiryTime = localStorage.getItem(
			LocalStorageKey.EXPIRE_TIME
		);
		if (!_sessionExpiryTime) return undefined;
		if (_sessionExpiryTime == 'undefined') return null;
		this._sessionExpiryTime = parseInt(_sessionExpiryTime);
		return this._sessionExpiryTime;
	}
	public set sessionExipryTime(time: number) {
		this._sessionExpiryTime = time;
		localStorage.setItem(
			LocalStorageKey.EXPIRE_TIME,
			this._sessionExpiryTime.toString()
		);
		this.authStore.dispatch(saveSessionExpiryTime({ time }));
	}
	public set token(token: LoginToekInterface) {
		this._token = token;
		localStorage.setItem(LocalStorageKey.TOKEN, this.encodedToken);
		this.authStore.dispatch(saveToken({ token }));
	}
	public get decodedIdTokenOld(): any {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				const idToken = this.encryptionService.decrypt(
					this._token.idToken,
					key
				);
				const decodedIdToken = idToken;
				const parsedToken = JSON.parse(decodedIdToken);
				return parsedToken;
			})
		);
	}

	public decodedIdToken(id): any {
		return this.authservice.getDecryptedIdToken(id).pipe(
			map((_token: any) => {
				return _token;
			})
		);
	}

	private get encodedToken(): string {
		return window.btoa(JSON.stringify(this._token));
	}
	private getDecodedToken(code: string): LoginToekInterface {
		return JSON.parse(window.atob(code));
	}
}
