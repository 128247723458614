import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { BroadcastMessageInterface } from 'src/app/modules/broadcast-and-messaging/_models/conversation.interface';
import { updateProfilePicUrl } from 'src/app/modules/broadcast-and-messaging/_state/chat.actions';
import { ChatState } from 'src/app/modules/broadcast-and-messaging/_state/chat.reducer';

@Component({
	selector: 'app-thread-card',
	templateUrl: './thread-card.component.html',
	styleUrls: ['./thread-card.component.scss'],
})
export class ThreadCardComponent implements OnInit {
	@Input() public conversation: BroadcastMessageInterface;
	@Input() public isOnPopup: boolean = false;
	public isSelected: boolean;
	public lastMessage: string;
	public lastMessageTime: string;
	public name: string;
	public imgPath: string = '../../../assets/images/slr-default-avatar.webp';

	constructor(
		private chatStore: Store<ChatState>,
		private fileUploadService: FileUploadService
	) {}

	ngOnInit(): void {
		this.intiThreadData();
	}

	private intiThreadData(): void {
		this.lastMessage = this.conversation.messageInfo;
		this.lastMessageTime = moment(this.conversation.createDate).format(
			'DD-MM'
		);
		this.name = this.conversation.created_by_name ?? '';

		if (!this.conversation.picturePath) {
			this.imgPath = '../../../assets/images/slr-default-avatar.webp';
		} else {
			if (this.conversation.pictureLoaded) {
				this.imgPath = this.conversation.picturePath;
				return;
			}
			this.fileUploadService
				.getPresignedUrl(this.conversation.picturePath)
				.subscribe((x) => {
					this.chatStore.dispatch(
						updateProfilePicUrl({
							messageId: this.conversation.messageId,
							path: x.s3PresingedURL,
						})
					);
					this.imgPath = x.s3PresingedURL;
				});
		}
	}
}
