<div class="popup-container">
    <div class="col-sm-12 d-flex justify-content-center">
      <div class="banner warning-banner p-2 col-sm-10 row align-items-center justify-content-center">
        <div class="col-sm-1 col-2 ps-0 d-flex align-items-center">
          <i class="material-icons-round warning-icon">warning</i>
        </div>
        <div class="col-sm-11">
          <b>{{ 'SKILLS_POPUP_TEXT' | translate }}</b>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center pt-3">
      <button class="btn submit-btn ps-3 pe-3" (click)="close(true)">
          {{ "yes" | translate }}
      </button>
      <button class="btn cancel-btn ms-2" (click)="close(false)">
          {{ "No" | translate }}
      </button>
  </div>
  </div>
  