
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';

export interface ConversationInterface {
	conversationId: number;
	conversationName?: string;
	users: Array<ChatUserInterface>;
	isGroupChat?: boolean;
	messages: Array<MessageInterface>;
	temporaryMessage?: MessageInterface;
	lastMessage?: MessageInterface;
}

export class ConversationModel implements ConversationInterface {
	conversationId: number = GenericHelper.getRandomNumber(4, 100);
	messages: MessageInterface[] = [];
	temporaryMessage?: MessageInterface;
	users: ChatUserInterface[] = [];
	conversationName: string = '';
	constructor(tempMessage: MessageInterface) {
		this.temporaryMessage = tempMessage;
	}
}

export interface MessageInterface {
	messageId?: number;
	from: ChatUserInterface;
	to?: Array<ChatUserInterface>;
	message: string;
	createdAt: string;
	delivered: boolean;
	read: boolean;
	isReply: boolean;
	replyMessage?: MessageInterface;
}

export class TemporaryMessage implements MessageInterface {
	from: ChatUserInterface;
	to?: Array<ChatUserInterface> = [];
	message: string = '';
	createdAt: string = new Date().toString();
	delivered: boolean = false;
	read: boolean = false;
	isReply: boolean = false;
	replyMessage?: MessageInterface = null;

	constructor(from?: ChatUserInterface, to?: Array<ChatUserInterface>) {
		this.from = from ?? null;
		this.to = to ?? [];
	}
}
export interface ChatUserInterface {
	userId: number;
	name: string;
	email: string;
	profilePicUrl: string;
}

/**
 * New Interface for Broadcast only
 */

export interface SendMessageApi {
	exemptUserIds: Array<number>;
	expireDate: Date;
	groupIds: Array<number>;
	roleIds: Array<number>,
	locationId: number;
	locationIds: Array<number>;
	message: string;
	sendEmails: boolean;
	userId: number;
	userIds: Array<number>;
	clientCode: string,
}

export interface BroadcastMessageInterface {
	messageId: number;
	messageInfo: string;
	createDate: Date;
	messageExpireDate: string;
	createdBy: number;
	updateDate: Date | string;
	updatedBy: number;
	messageCreatedLocation: number;
	isSeen: boolean;
	activeFlag: boolean;
	flagSendEmail: boolean;
	created_by_name: string;
	picturePath: string;
	pictureLoaded: boolean;
}
