import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	filter,
	map,
	skipWhile,
	Subject,
	take,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs';
import { LocalStorageKey } from 'src/app/core/models/local-storage-key.enum';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { HelpSystemService } from 'src/app/core/services/help-system.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { ChangePasswordPopupComponent } from 'src/app/modules/authentication/change-password-popup/change-password-popup.component';
import {
	handleLinkedUserChange,
	logout,
	refreshUserSession,
} from 'src/app/modules/authentication/_state/authentication.action';
import { AuthenticationState } from 'src/app/modules/authentication/_state/authentication.reducer';
import { updateUnseenMessageCount } from 'src/app/modules/broadcast-and-messaging/_state/chat.actions';
import {
	ChatState,
	getUnseenMessageCount,
} from 'src/app/modules/broadcast-and-messaging/_state/chat.reducer';
import { UserLocationAPIInterface } from 'src/app/modules/manage-users/_models/new-user-.model';
import { ChatPopupComponent } from '../../components/chat-popup/chat-popup.component';
import { DropdownInterface } from '../../components/dropdowns/slr-drp01/dropdown.model';
import { NotificationPopupComponent } from '../../components/notification-popup/notification-popup.component';
import { SiteLocationComponent } from '../../components/site-location/site-location.component';

import { updateSelectedLocation } from '../../_state/shared.actions';
import {
	getDPValues,
	getLocationData,
	getRootLocationId,
	getLocationType,
	getSelectedLocation,
	SharedState,
	getProfilePic,
	getLinkedUsers,
	getProductAccessSettings,
	getBrandingSettingsByName,
	getUserPrivileges,
} from '../../_state/shared.reducer';
import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { BroadcastPopupComponent } from 'src/app/shared/components/broadcast-popup/broadcast-popup.component';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { SettingsMap } from 'src/app/modules/user-settings/_models/settins-map.enum';

export const DEFAULT_LOGO_PATH = 'assets/images/SurgeLearning_logo.svg';
interface IBreadCrumb {
	title: string;
	url: string;
	queryParams: string;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('child') public child: SiteLocationComponent;
	@ViewChild('parent', { static: false })
	public parent: SiteLocationComponent;
	@ViewChild('linkedUser') public linkedUser: SiteLocationComponent;
	@ViewChild('headerNav') public headerElement: ElementRef;
	public permissionHelper = new PermissionHelper();
	public openConfirmationModal = false;
	public confirmationModalSize: string = '';
	selectedOption: string = '';
	public locationType: 'grandparent' | 'parent' | 'child';
	public showChild = false;
	public showLinkedUser = false;
	public picPath: string;
	public logoImgSrc: string; // = DEFAULT_LOGO_PATH;

	public parentValue: number;
	public parentData: Array<DropdownInterface>;
	public childData: Array<DropdownInterface>;
	public linkeUserData: Array<DropdownInterface>;
	private _gpId: number;
	private locationData: UserLocationAPIInterface;

	public rootLocation: number;
	public selectedLocation: number;

	private matDialogRef: MatDialogRef<ChatPopupComponent>;
	private matNotificationDialogRef: MatDialogRef<NotificationPopupComponent>;
	private messageDialogOpen: boolean = false;
	private unsubscriber$ = new Subject<void>();

	public breadCrumbItems = new Array<IBreadCrumb>();
	public articles: [];
	public articleToShow;
	public routeLabels = [];

	public headerNameForChildLocations: string = '';

	public notificationCount: number;
	public messageCount: number;
	private stopSettingsObservable = new Subject<void>();

	constructor(
		private router: Router,
		private matDialog: MatDialog,
		private sharedStore: Store<SharedState>,
		private chatStore: Store<ChatState>,
		private cdr: ChangeDetectorRef,
		private localData: LocalDataService,
		private translate: TranslateService,
		private helpService: HelpSystemService,
		private broadcastService: BroadcastService,
		private authStore: Store<AuthenticationState>,
		private fileUploadService: FileUploadService
	) {
		this.localData.getRouteChange().subscribe((event) => {
			this.breadCrumbItems = event['breadCrumb'];
			this.routeLabels = [];
		});

		this.sharedStore
			.pipe(
				select(getRootLocationId),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => (this.rootLocation = res),
			});
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value && !!value.location)
			)
			.subscribe({
				next: (res) => (this.selectedLocation = res.location.id),
			});
		this.chatStore
			.pipe(select(getUnseenMessageCount), takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => (this.messageCount = res),
			});
	}

	ngOnInit(): void {
		this.getNotificationCount();
		this.sharedStore
			.pipe(
				select(getProfilePic),
				skipWhile((value) => !value),
				takeUntil(this.unsubscriber$)
			)
			.subscribe({
				next: (res) => {
					this.picPath = res;
					this.cdr.detectChanges();
				},
			});
	}

	ngAfterViewInit(): void {
		this.getHeaderProperties();
		this.initLocation();
		this.linkedUserAccounts();
	}

	getNotificationCount() {
		this.helpService
			.getNotificationCounts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (response: any) => {
					this.notificationCount = response.data['notificationCount'];
					this.chatStore.dispatch(
						updateUnseenMessageCount({
							newCount: response.data['messageCount'],
						})
					);
					this.cdr.detectChanges();
				},
				error: (err) => {
					console.log('Error in Notification Count API: ', err);
				},
			});
	}

	private getHeaderProperties(): void {
		this.sharedStore
			.pipe(
				select(
					getBrandingSettingsByName(
						SettingsMap.custom_logo,
						SettingsMap.header_color,
						SettingsMap.header_background_color
					)
				),
				takeUntil(this.unsubscriber$),
				takeUntil(this.stopSettingsObservable)
			)
			.subscribe({
				next: (res) => {
					this.headerElement.nativeElement.style.backgroundColor =
						res[2] ?? this.localData.defaultColors[2];
					this.headerElement.nativeElement.style.color =
						res[1] ?? this.localData.defaultColors[1];
					if (res[0] && res[0] !== 'null') {
						this.fileUploadService
							.getPresignedUrl(res[0])
							.subscribe({
								next: (res) => {
									this.logoImgSrc =
										res.s3PresingedURL ?? DEFAULT_LOGO_PATH;
									const navLink =
										document.getElementById('navLink');
									const logoImg = navLink
										.childNodes[0] as HTMLImageElement;
									logoImg.src =
										res.s3PresingedURL ?? DEFAULT_LOGO_PATH;
								},
							});
					} else {
						this.logoImgSrc = DEFAULT_LOGO_PATH;
						const navLink = document.getElementById('navLink');
						const logoImg = navLink
							.childNodes[0] as HTMLImageElement;
						logoImg.src = DEFAULT_LOGO_PATH;
					}
				},
			});
	}

	private linkedUserAccounts(): void {
		this.sharedStore
			.pipe(
				select(getLinkedUsers),
				takeUntil(this.unsubscriber$),
				withLatestFrom(this.sharedStore.pipe(select(getUserPrivileges)))
			)
			.subscribe({
				next: ([res, userPrivileges]) => {
					this.showLinkedUser = (res && res.linked) ?? false;
					this.cdr.detectChanges();
					if (this.showLinkedUser) {
						const currentUser = `${userPrivileges.siteCode}.${userPrivileges.userName}`;
						this.linkeUserData = res.linkedUsers.map((x, index) => {
							return {
								id: x.locationId,
								alternateId: `${x.clientCode}.${x.username}`,
								name: `${x.locationName} (${x.clientCode}.${x.username})`,
								data: `${x.clientCode}.${x.username}`,
							};
						});
						
						this.showChild = false;
						setTimeout(() => {
							if (this.showLinkedUser) {
								console.log(this.rootLocation);
								this.linkedUser.value = currentUser;
							}
							this.cdr.detectChanges();
						}, 10);
					}
				},
			});
	}
	getLabelForHelpSystem() {
		if (this.breadCrumbItems) {
			let filteredBreadCrumbItems = this.breadCrumbItems.filter(
				(route) => route.title !== 'HOME'
			);

			filteredBreadCrumbItems.forEach((routeLabel) => {
				this.translate
					.get(routeLabel?.title)
					.subscribe((data) =>
						this.routeLabels.push(data.toLowerCase())
					);
			});

			this.translate
				.get(
					this.breadCrumbItems[this.breadCrumbItems.length - 1]?.title
				)
				.subscribe((data) => this.getContextualHelp(data));
		}
	}

	getContextualHelp(label: string) {
		this.helpService
			.helpSystem(label.toLowerCase())
			.subscribe((data: any) => {
				this.articles = data?.results;
				this.getArticleBasedOnLabel(this.articles);
			});
	}

	getArticleBasedOnLabel(searchedArticles) {
		this.articleToShow = searchedArticles.filter((article) =>
			this.checkLables(this.routeLabels, article?.label_names)
		)?.[0];
	}

	checkLables(routeLabels, articleLabels) {
		if (routeLabels.length === articleLabels.length) {
			return articleLabels.every((element) => {
				if (routeLabels.includes(element.toLowerCase())) {
					return true;
				}
				return false;
			});
		}
		return false;
	}

	getHelp() {
		this.broadcastService.showHelpSystem();
	}

	openHelpModal(modalHeader: string, modalSize?: string) {
		this.openConfirmationModal = true;
		this.localData.modalHeader = modalHeader;
		this.confirmationModalSize = modalSize || '';
	}

	closeConfirmationModal(event: boolean) {
		this.openConfirmationModal = false;
	}

	public handleSelectChange(
		selectedOption: DropdownInterface,
		childId?: number
	) {
		if (selectedOption.id == this._gpId) {
			/**
			 * a grandparent is selected
			 */
			this.childData = null;
			this.showChild = false;
			this.udpateLocation(selectedOption, 1, 'grandparent');
			setTimeout(() => {
				location.reload();
			}, 10);
			return;
		}

		/**
		 * A parent is selected
		 */
		this.showChild = true;
		const parent = this.locationData.grandParent.parents.find(
			(x) => x.parentLocationId == selectedOption.id
		);
		if (parent) {
			this.childData = parent.childs.map((x) => {
				return {
					id: x.locationId,
					name: `${x.locationName} (${x.siteCode})`,
					data: x.siteCode,
				};
			});
			if (this.child) this.child.value = null;
		}

		this.udpateLocation(selectedOption, 1, 'parent');
		setTimeout(() => {
			location.reload();
		}, 10);
	}

	public handleSelectChangeSubLocation(selectedOption: DropdownInterface) {
		this.udpateLocation(selectedOption, 0, 'child');
		setTimeout(() => {
			location.reload();
		}, 10);
	}

	public handleLinkedUserChange(event: DropdownInterface): void {
		const [code, username] = event.data.split('.');
		this.stopSettingsObservable.next();
		this.authStore.dispatch(
			handleLinkedUserChange({ payload: { code, username } })
		);
	}

	/**
	 *
	 * @param selectedOption  - selected dropdown location
	 * @param type  - child - 0, parent - 1
	 * @param locationType - 'child'|'grandparent'|'parent'
	 */
	private udpateLocation(
		selectedOption: DropdownInterface,
		type: 0 | 1,
		locationType: 'child' | 'grandparent' | 'parent'
	): void {
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				withLatestFrom(this.sharedStore.pipe(select(getDPValues))),
				take(1)
			)
			.subscribe({
				next: (res) => {
					this.sharedStore.dispatch(
						updateSelectedLocation({
							id: selectedOption.id,
							name: selectedOption.name,
							sitecode: selectedOption.data,
							dp1: type == 0 ? res[1].dp1 : selectedOption.id,
							dp2: type == 1 ? null : selectedOption.id,
						})
					);
				},
			});
		localStorage.setItem(
			LocalStorageKey.CURRENT_LOCATION_TYPE,
			locationType
		);
	}

	public logout(): void {
		this.authStore.dispatch(logout({}));
	}

	public navigate(to: string): void {
		this.router.navigate([`/${to}`]);
	}

	public openMessagePopup(): void {
		this.messageDialogOpen = !this.messageDialogOpen;

		if (this.messageDialogOpen) {
			this.matDialogRef = this.matDialog.open(ChatPopupComponent, {
				panelClass: ['no-padding-popup', 'mobilechatpopup'],

				backdropClass: 'no-class',
				disableClose: false,
			});
			this.matDialogRef.afterClosed().subscribe({
				next: (res) =>
					(this.messageDialogOpen = !this.messageDialogOpen),
			});
		} else {
			this.matDialogRef.close();
		}
	}

	openNotificationPopup() {
		this.messageDialogOpen = !this.messageDialogOpen;
		let notifications = [];
		this.helpService
			.getNotifications()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res: any) => {
					notifications = res.data;
					if (this.messageDialogOpen) {
						this.matNotificationDialogRef = this.matDialog.open(
							NotificationPopupComponent,
							{
								panelClass: [
									'no-padding-popup',
									'notification-mobile-chat-popup',
								],
								backdropClass: 'no-class',
								disableClose: false,
								data: notifications,
							}
						);
						this.matNotificationDialogRef.afterClosed().subscribe({
							next: (res) =>
								(this.messageDialogOpen =
									!this.messageDialogOpen),
						});
					} else {
						this.matNotificationDialogRef.close();
					}
				},
				error: (err) => {},
			});
	}

	private initLocation(): void {
		this.sharedStore
			.pipe(
				select(getLocationType),
				withLatestFrom(
					this.sharedStore.pipe(select(getLocationData)),
					this.sharedStore.pipe(select(getSelectedLocation)),
					this.sharedStore.pipe(select(getDPValues))
				),
				// takeUntil(this.unsubscriber$)
				take(1),
				map(([type, location, selectedLocation, dpValues]) => {
					this.locationData = location;
					switch (type) {
						case 'child':
							this.parentData = [
								{
									id: location.child.locationId,
									name: `${location.child.locationName} (${location.child.siteCode})`,
									data: location.child.siteCode,
								},
							];
							this.initSingleLocationHeaderName(
								location.child.locationName
							);
							// this.parent.value = location.child.locationId;
							break;
						case 'grandparent':
							this._gpId =
								location.grandParent.grandParentLocationId;
							this.parentData = [
								{
									id: location.grandParent
										.grandParentLocationId,
									name: `${location.grandParent.grandParentLocationName} (${location.grandParent.siteCode})`,
									data: location.grandParent.siteCode,
								},
							];
							location.grandParent.parents.forEach((x) => {
								this.parentData = [
									...this.parentData,
									{
										id: x.parentLocationId,
										name: `${x.parentLocationName} (${x.siteCode})`,
										data: x.siteCode,
									},
								];
							});
							// setTimeout(() => {
							// 	this.parent.value = dpValues.dp1;
							// }, 100);
							// this.parentValue = dpValues.dp1;

							if (this._gpId !== selectedLocation.location.id) {
								this.showChild = true;
								const parent =
									this.locationData.grandParent.parents.find(
										(x) =>
											x.parentLocationId == dpValues.dp1
									);
								if (parent) {
									this.childData = parent.childs.map((x) => {
										return {
											id: x.locationId,
											name: `${x.locationName} (${x.siteCode})`,
											data: x.siteCode,
										};
									});
									if (this.child) this.child.value = null;
								}
								// setTimeout(() => {
								// 	this.child.value = dpValues.dp2;
								// 	this.cdr.detectChanges();
								// }, 10);
							}

							break;
						case 'parent':
							break;
					}
					this.cdr.detectChanges();
					return dpValues;
				})
			)
			.subscribe({
				next: (dpValues) => {
					if (this.parent) this.parent.value = dpValues.dp1;
					setTimeout(() => {
						dpValues.dp2 && (this.child.value = dpValues.dp2);
						this.cdr.detectChanges();
					}, 10);
				},
			});
	}

	public changePassword(): void {
		this.matDialog.open(ChangePasswordPopupComponent, {
			width:
				this.broadcastService.screenSize.value === 'small'
					? '100%'
					: '40%',
			disableClose: true,
		});
	}
	addBrodcast() {
		const smallConfig = {
			width: '400px',
			maxWidth: '100vw',
			panelClass: 'mob-modal-padding',
		};
		const largeConfig = { width: '100%' };
		const defaultConfig = { height: 'auto', disableClose: true };
		setTimeout(() => {
			this.matDialog.open(
				BroadcastPopupComponent,
				this.broadcastService.screenSize.value === 'small'
					? {
							...smallConfig,
							...defaultConfig,
					  }
					: {
							...largeConfig,
							...defaultConfig,
					  }
			);
		}, 100);
	}

	private initSingleLocationHeaderName(locationName: string): void {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value),
				takeUntil(this.unsubscriber$)
			)
			.subscribe({
				next: (res) => {
					const name = `${res.firstName ?? ''} ${res.lastName ?? ''}`;
					this.headerNameForChildLocations = `${name} (${locationName})`;
				},
			});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
